<div class="msidebar msidebar__content">
    <div class="p-2 msidebar__logo">
        <img *ngIf="!customerConfig" src="/assets/imgs/logo-funeup.svg" alt="FuneUp" />
        <img *ngIf="customerConfig" [src]="customerConfig.logo.url | apiUrl" alt="" />
    </div>
    <mat-nav-list>
        <a mat-list-item *ngIf="isSubcategory()" [routerLink]="profileId ? ['/', profileId, 'home'] : ['/computed-stats']">
            <span class="msidebar__label d-flex align-items-center" matListItemLine> <mat-icon svgIcon="arrow_left" class="msidebar__icon me-2"></mat-icon>Retour</span>
        </a>
        <ng-container  *ngFor="let link of linkTree; let i = index">
            <ng-container [ngSwitch]="link.type">
                <ng-container *ngSwitchCase="'back'">
                    <a mat-list-item [routerLink]="link.url">
                        <span class="sidebar__label d-flex align-items-center" matListItemLine> <mat-icon svgIcon="arrow_left" class="msidebar__icon me-2"></mat-icon>{{ link.label }}</span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'title'">
                    <div mat-list-item>
                        <span matListItemLine class="msidebar__label px-3 mt-2 mb-2 fs-6">{{link.label}}</span>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <div class="msidebar__title" *ngIf="isSubcategory()">
                        <span matSubheader class="msidebar__label fw-bolder">{{ link.label }}</span>
                    </div>

                    <ng-container *ngIf="!isSubcategory()">
                        <ng-container *ngTemplateOutlet="isLink; context: {link: link, collapse: false, collapsed: false}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="isSubcategory() && link.subNav">
                        <ng-container *ngFor="let subLink of link.subNav">
                            <ng-container *ngTemplateOutlet="isLink; context: {link: subLink, collapse: false, collapsed: true}"></ng-container>
                            <ng-container *ngIf="subLink.subNav">
                                <ng-container *ngFor="let subsubLink of subLink.subNav">
                                    <ng-container *ngTemplateOutlet="isLink; context: {link: subsubLink, collapse: true, collapsed: false}"></ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #isLink let-link="link" let-collapse="collapse" let-collapsed="collapsed">
            <ng-container *appHasRole="link.requiredRoles">
                <a mat-list-item *ngIf="link.label !== 'Déposer un avis'"
                   class="msidebar__link"
                   [class.ps-4]="collapse"
                   [class.collapse]="collapse"
                   [class.active]="rla.isActive"
                   [class.collapsed]="collapsed && (rla.isActive || (link.activeLink && link.activeLink.includes(router.url.split('?')[0])))"
                   [class.not-collapsed]="collapsed && !rla.isActive"
                   #rla="routerLinkActive"
                   [routerLink]="link.url"
                   routerLinkActive
                   [target]="(link.target) ? link.target : '_self'"
                   [queryParamsHandling]="link.queryParamsHandling"
                   [queryParams]="link.queryParams"
                   (click)="menuClick.emit()"

                >
                    <span class="indicator" [style.background-color]="customerConfig?.style.selectedMenu" *ngIf="(!collapsed || !link.subNav) && ((link.activeLink && link.activeLink.includes(router.url.split('?')[0])) || rla.isActive)"></span>
                    <mat-icon class="msidebar__icon me-3" [svgIcon]="link.icon"></mat-icon>
                    <span class="msidebar__label flex-grow-1">{{ link.label }}</span>
                    <mat-icon *ngIf="link.subNav" [svgIcon]="(collapsed && (rla.isActive || (link.activeLink && link.activeLink.includes(router.url.split('?')[0])))) ? 'arrow_down' : 'arrow_right'" class="msidebar__icon ms-2"></mat-icon>
                </a>
                <a mat-list-item *ngIf="link.label === 'Déposer un avis'"
                   #rla="routerLinkActive"
                   [href]="link.url"
                   routerLinkActive
                   [target]="(link.target) ? link.target : '_self'"
                   (click)="menuClick.emit()"
                >
                    <mat-icon class="msidebar__icon me-3" [svgIcon]="link.icon"></mat-icon>
                    <span class="msidebar__label flex-grow-1">{{ link.label }}</span>
                    <mat-icon *ngIf="link.subNav" svgIcon="arrow_right" class="msidebar__icon ms-2"></mat-icon>
                </a>
            </ng-container>
        </ng-template>
    </mat-nav-list>
</div>
<mat-nav-list>
    <a mat-list-item href="https://24905542.hs-sites-eu1.com/knowledge"  target="_blank">
        <mat-icon svgIcon="help" class="msidebar__icon me-2"></mat-icon>
        <span class="msidebar__label d-flex align-items-center"> Besoin d'aide ?</span>
    </a>
</mat-nav-list>