import { NgModule } from '@angular/core';
import { MheaderComponent } from '@components/mheader/mheader.component';
import { ProfileSelectorComponent } from '@components/profile-selector/profile-selector.component';
import { SharedModule } from '@modules/shared.module';
import { MlayoutComponent } from '@components/mlayout/mlayout.component';
import { MsidebarComponent } from '@components/msidebar/msidebar.component';

@NgModule({
  declarations: [
    MlayoutComponent,
    MheaderComponent,
    ProfileSelectorComponent,
  ],
  imports: [
    SharedModule,
    MsidebarComponent
  ],
  exports: [
    MlayoutComponent,
    MheaderComponent
  ],
  providers: [
  ]
})
export class MlayoutModule { }
