export const State = [
  {
    label: 'Activé',
    value: 'active',
    icon: 'active',
  },
  {
    label: 'Suspendu',
    value: 'disabled',
    icon: 'disabled',
  },
  {
    label: 'Supprimé',
    value: 'deleted',
    icon: 'deleted',
  },
  {
    label: 'Brouillon',
    value: 'draft',
    icon: 'disabled',
  },
  {
    label: 'Payée',
    value: 'paid',
    icon: 'active',
  },
  {
    label: 'Délivrée',
    value: 'delivered',
    icon: 'delivered',
  },
  {
    label: 'Remboursée',
    value: 'refunded',
    icon: 'refunded',
  },
  {
    label: 'Annulée',
    value: 'cancelled',
    icon: 'disabled',
  },
  {
    label: 'Activé',
    value: true,
    icon: 'active',
  },
  {
    label: 'Desactivé',
    value: false,
    icon: 'disabled',
  },
  {
    label: 'Reçu',
    value: 'ANSWERED',
    icon: 'active',
  },
  {
    label: 'Manqué',
    value: 'NOT_ANSWERED',
    icon: 'disabled',
  },
  {
    label: 'Non traité',
    value: 'enquired',
    icon: 'timer',
  },
  {
    label: 'Concrétisé',
    value: 'won',
    icon: 'active',
  },
  {
    label: 'En cours',
    value: 'sent',
    icon: 'time-delay',
  },
  {
    label: 'Perdu',
    value: 'lost',
    icon: 'deleted',
  },
  {
    label: 'Non traité',
    value: 'unprocessed',
    icon: 'timer',
  },
  {
    label: 'En cours',
    value: 'wip',
    icon: 'time-delay',
  },
];
