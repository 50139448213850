import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { SidebarNavigationLink } from '@models/sidebar-navigation';
import { combineLatest, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OauthService } from '@services/oauth.service';
import { UserRoles } from '@models/user-roles';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { getProfileSidebarNavigationLink } from '@components/sidebar/sidebar-profile';
import { getAdminSidebarNavigationLink } from '@components/sidebar/sidebar-admin';
import { CustomerConfig } from '@models/customer-config';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@modules/shared.module';
import { MenuConfig } from '@models/menu-config';
import { ProfileConfig } from '@models/profile-config.data-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Services } from '@models/services';


@Component({
  selector: 'app-msidebar',
  templateUrl: './msidebar.component.html',
  styleUrls: ['./msidebar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule
  ],
})
export class MsidebarComponent implements OnDestroy {
  @Output() public menuClick = new EventEmitter();

  public linkTree: Array<SidebarNavigationLink> = [];
  public profileConfig: ProfileConfig;
  private baseLinks;

  profileId: string;
  roles = UserRoles;
  menuConfig!: MenuConfig;
  unsubscribe: Subscription[] = [];
  customerConfig: CustomerConfig;
  services: Services;
  haveManyProfiles = false;
  userRole: UserRoles;
  public constructor(
    private router: Router,
    private oauthService: OauthService,
    public activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;

    this.registerIcons();

    const sub = combineLatest([
      this.router.events
        .pipe(
          distinctUntilChanged((prev, curr) => !!prev && !!curr && prev === curr),
          filter((e) => e instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => route.firstChild.firstChild.snapshot),
          filter(value => value !== undefined)
        ),
      this.oauthService.getAuthenticatedUser()
        .pipe(
          filter((u) => !!u)
        )
    ])
      .pipe(
        tap(([route, user]) => {
          this.haveManyProfiles = user.profiles.length > 1 || user.role === this.roles.SUPER_ADMIN;
          this.userRole = user.role;

          if (route && route.data.profile) {
            this.menuConfig = route.data.menuConfig;
            this.profileId = route.data.profile.id;
            this.profileConfig = route.data.profileConfig;
            this.services = route.data.services;

            this.baseLinks = getProfileSidebarNavigationLink(
              route.data.profile,
              route.data.menuConfig,
              route.data.profileIntegrations,
              route.data.services
            );
          } else {
            this.profileId = null;
            this.baseLinks = getAdminSidebarNavigationLink(
              route.data.menuConfig,
              this.haveManyProfiles,
              this.userRole
            );
          }

          const prefilteredSidebarNavigationLinkTree = this.filterSidebarNavigationLinkTreeWithUserRoles(user.role);
          const currentSidebarNavigationLink = this.getCurrentSidebarNavigationLink(prefilteredSidebarNavigationLinkTree);
          if (currentSidebarNavigationLink && currentSidebarNavigationLink.subNav) {
            this.linkTree = [currentSidebarNavigationLink];
          } else {
            this.linkTree = prefilteredSidebarNavigationLinkTree;
          }

        })
      )
      .subscribe();

    this.unsubscribe.push(sub);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => (sb && !sb.closed) ? sb.unsubscribe() : null);
  }

  private filterSidebarNavigationLinkTreeWithUserRoles(role): Array<SidebarNavigationLink> {
    // Add condition remove links from SIDEBAR if config == false
    return this.baseLinks.filter(function recursiveFilter(link) {
      if (link.subNav) {
        link.subNav = link.subNav.filter(recursiveFilter);
      }
      if (link.requiredRoles) {
        return link.requiredRoles.includes(role);
      }
      return link.subNav ? link.subNav.length : true;
    });
  }

  private getCurrentSidebarNavigationLinkRecursive(link: SidebarNavigationLink): SidebarNavigationLink | undefined {
    if (link.subNav) {
      const subLinkMatch = link.subNav.find(subLink => {
        return (
          (this.router.url.split('?')[0].startsWith(link.url?.join(''))) ||
          (subLink.url?.join('') === this.router.url.split('?')[0]) ||
          (this.router.url.split('?')[0].startsWith(subLink.url?.join('')))
        );
      });

      if (subLinkMatch) {
        return link;
      }
    }

    if (link.url && link.url.join('') === this.router.url) {
      return link;
    }

    if (link.subNav && link.subNav.length > 0) {
      for (const subLink of link.subNav) {
        const result = this.getCurrentSidebarNavigationLinkRecursive(subLink);
        if (result) {
          return link;
        }
      }
    }

    return undefined;
  }

  private getCurrentSidebarNavigationLink(sideBarNavigationLinkTree: Array<SidebarNavigationLink>): SidebarNavigationLink | undefined {
    for (const link of sideBarNavigationLinkTree) {
      const result = this.getCurrentSidebarNavigationLinkRecursive(link);
      if (result) {
        return link;
      }
    }

    return undefined;
  }

  public isSubcategory(): boolean {
    return this.linkTree.length === 1 && !!this.linkTree[0].subNav?.length;
  }

  private registerIcons(): void {
    this.matIconRegistry
      .addSvgIcon('dashboard', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/dashboard.svg`))
      .addSvgIcon('family-space', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/family-space.svg`))
      .addSvgIcon('call', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/call.svg`))
      .addSvgIcon('estimate', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/estimate.svg`))
      .addSvgIcon('review', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/review.svg`))
      .addSvgIcon('shop', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/shop.svg`))
      .addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/settings.svg`))
      .addSvgIcon('stats', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/stats.svg`))
      .addSvgIcon('arrow_left', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/arrow-left.svg`))
      .addSvgIcon('arrow_right', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/arrow-right.svg`))
      .addSvgIcon('arrow_down', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/arrow-down.svg`))
      .addSvgIcon('client', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/client.svg`))
      .addSvgIcon('contact', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/contact.svg`))
      .addSvgIcon('invoice', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/invoice.svg`))
      .addSvgIcon('place', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/place.svg`))
      .addSvgIcon('store', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/store.svg`))
      .addSvgIcon('user', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/user.svg`))
      .addSvgIcon('profile', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/profile.svg`))
      .addSvgIcon('adwords', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/adwords.svg`))
      .addSvgIcon('cms', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/cms.svg`))
      .addSvgIcon('collect-review', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/collect-review.svg`))
      .addSvgIcon('customer-service', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/customer-service.svg`))
      .addSvgIcon('customize', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/customize.svg`))
      .addSvgIcon('desktop', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/desktop.svg`))
      .addSvgIcon('family', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/family.svg`))
      .addSvgIcon('flower-bouquet', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/flower-bouquet.svg`))
      .addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/home.svg`))
      .addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/info.svg`))
      .addSvgIcon('plant-a-tree', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/plant-a-tree.svg`))
      .addSvgIcon('research', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/research.svg`))
      .addSvgIcon('manege-review', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/manege-review.svg`))
      .addSvgIcon('sepulture', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/sepulture.svg`))
      .addSvgIcon('webpage', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/webpage.svg`))
      .addSvgIcon('settings-2', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/settings-2.svg`))
      .addSvgIcon('desktop', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/desktop.svg`))
      .addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/menu.svg`))
      .addSvgIcon('flower', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/flower.svg`))
      .addSvgIcon('management', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/management.svg`))
      .addSvgIcon('business', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/business.svg`))
      .addSvgIcon('tag', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/tag.svg`))
      .addSvgIcon('bag', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/bag.svg`))
      .addSvgIcon('funeral', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/funeral.svg`))
      .addSvgIcon('flower-shop', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/flower-shop.svg`))
      .addSvgIcon('esm', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/esm.svg`))
      .addSvgIcon('form', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/form.svg`))
      .addSvgIcon('esm-product', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/esm-product.svg`))
      .addSvgIcon('oak', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/oak.svg`))
      .addSvgIcon('plant-a-tree', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/plant-a-tree.svg`))
      .addSvgIcon('save-nature', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/save-nature.svg`))
      .addSvgIcon('plaque', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/plaque.svg`))
      .addSvgIcon('award', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/award.svg`))
      .addSvgIcon('product', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/product.svg`))
      .addSvgIcon('save-nature', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/save-nature.svg`))
      .addSvgIcon('tribute-book', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/tribute-book.svg`))
      .addSvgIcon('catalogue', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/catalogue.svg`))
      .addSvgIcon('cart', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/cart.svg`))
      .addSvgIcon('partners', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/partners.svg`))
      .addSvgIcon('preferences-default', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/preferences-default.svg`))
      .addSvgIcon('preferences-emails', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/preferences-emails.svg`))
      .addSvgIcon('help', this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/sidebar/help.svg`));
  }
}
